.home-view {
  height: 100%;
}

.home-buttons-holder {
  display: flex;
  flex-direction: column;
  text-align: center;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-buttons-group {
  margin: 0.375rem 0;
}

.home-buttons-group button {
  background-color: var(--secondary-color);
  /* color: var(--text-color); */
  border: none;
  border-radius: 0.375rem;
  margin: 0.25rem;

  font-size: 2rem;
}

.home-buttons-choose {
  padding: 0.375rem 1rem;
}

.home-buttons-random {
  padding: 0.375rem 0.5rem;
}
