.loading-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loader-holder {
  font-size: 4rem;
  font-family: 'Roboto Slab', serif;
}

.loader {
  --fa-animation-duration: 2000ms;
}

.loader-1 {
  --fa-animation-delay: 0ms;
}

.loader-2 {
  --fa-animation-delay: 333ms;
}

.loader-3 {
  --fa-animation-delay: 667ms;
}
