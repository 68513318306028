html {
  font-size: 16px;
}

:root {
  --background-color: #eee;
  --primary-color: rgb(36, 80, 136);
  --secondary-color: rgb(36, 80, 136);

  --correct-status-color: #4e8749;
  --inword-status-color: #ac9d5a;
  --incorrect-status-color: rgb(47, 47, 47);
  --unguessed-status-color: rgb(105, 105, 105);

  --text-color: #eee;
}

html[data-theme='light'] {
  --background-color: #eee;
  --text-color: #222;
}

html[data-theme='dark'] {
  --background-color: #222;
  --text-color: #eee;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  user-select: none;
}
