.guesses {
  flex: 1 1 auto;
  overflow-y: auto;
}

.guess-row {
  display: flex;
  justify-content: center;
  margin-bottom: 0.125rem;
}

.guess-row:first-of-type {
  /* margin-top: 1rem; */
}

.guess-row:last-of-type {
  margin-bottom: 1rem;
}

.guess-row-4 .letter-box {
  height: calc(calc(100vw - 0.625rem - 5rem) / 4);
  width: calc(calc(100vw - 0.625rem - 5rem) / 4);
}

.guess-row-5 .letter-box {
  height: calc(calc(100vw - 0.625rem - 5rem) / 5);
  width: calc(calc(100vw - 0.625rem - 5rem) / 5);
}

.guess-row-6 .letter-box {
  height: calc(calc(100vw - 0.625rem - 5rem) / 6);
  width: calc(calc(100vw - 0.625rem - 5rem) / 6);
}

.guess-row-7 .letter-box {
  height: calc(calc(100vw - 0.625rem - 5rem) / 7);
  width: calc(calc(100vw - 0.625rem - 5rem) / 7);
}

.guess-row-8 .letter-box {
  height: calc(calc(100vw - 0.625rem - 5rem) / 8);
  width: calc(calc(100vw - 0.625rem - 5rem) / 8);
}

.letter-box {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0.2rem;

  text-transform: uppercase;
  font-weight: bold;
  font-size: 1.75rem;

  max-width: 4rem;
  max-height: 4rem;

  user-select: none;

  background-color: var(--background-color);
  border: 2px solid var(--incorrect-status-color);
}

.guess-row-6 .letter-box,
.guess-row-7 .letter-box,
.guess-row-8 .letter-box {
  margin: 0.125rem;
  font-weight: normal;
}

.letter-box.correct {
  background-color: var(--correct-status-color);
  border: 2px solid var(--correct-status-color);
}

.letter-box.inword {
  background-color: var(--inword-status-color);
  border: 2px solid var(--inword-status-color);
}

.letter-box.incorrect,
.letter-box.inprogress {
  background-color: var(--incorrect-status-color);
  /* border: 1px solid var(--unguessed-status-color); */
  border: 2px solid var(--incorrect-status-color);
}

.guess-row.shake {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
