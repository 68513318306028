.app {
  color: var(--text-color);
  background-color: var(--background-color);

  height: 100vh;
  max-height: -webkit-fill-available;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
}

.header-holder {
  height: 3.5rem;
}

.main-area {
  height: calc(100% - 3.5rem);
}

a {
  text-decoration: none !important;
  color: var(--text-color) !important;
}
