.game-list-view {
  height: 100%;
  position: relative;
  overflow: auto;
}

.game-list-buttons-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;

  width: calc(100vw - 4rem);
  max-width: 1000px;

  margin: 2rem auto;
  min-height: min-content;
}

.game-list-view-game-button-link {
  position: relative;
  margin: 0.5rem;
}

.game-list-view-game-button {
  width: calc(100vw / 20);
  height: calc(100vw / 20);

  min-width: 4rem;
  min-height: 4rem;

  background-color: var(--background-color);
  color: var(--secondary-color);
  border: 3px solid var(--secondary-color);
  border-radius: 0.375rem;

  font-size: 2rem;
  font-weight: bold;
  text-align: center;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.game-list-view-game-button.solved {
  background-color: var(--correct-status-color);
  border: 3px solid var(--correct-status-color);
  color: var(--text-color);
}

.game-list-view-game-button.in-progress {
  background-color: var(--inword-status-color);
  border: 3px solid var(--inword-status-color);
  color: var(--text-color);
}

.game-list-buttons-holder .guesses-badge {
  position: absolute;
  top: -0.4rem;
  right: -0.4rem;
  background-color: darkgoldenrod;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 0.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0.2rem;
  font-size: 0.9rem;
}
